<template lang="">
    <div>
       <Detail /> 
    </div>
</template>
<script>
export default {
    components:{
        Detail : () => import("@/components/properties/PropertyDetail.vue")
    }
}
</script>
<style lang="">
    
</style>